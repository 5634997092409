import React from 'react';
import { IoArrowRedoOutline } from 'react-icons/io5';

import Link from 'components/Link';

import useViewer from 'hooks/useViewer';
import { AuthStatus } from 'constants/auth';
import { getMasterClassPage } from 'constants/pages';
import { MasterclassLadingPageProps } from './types';

export const CardCourse: React.FC<MasterclassLadingPageProps['data'][0]> = ({
  slug,
  title,
  description,
  featuredImageUrl,
}) => {
  const viewer = useViewer();

  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <Link href={getMasterClassPage(slug || '')} className="flex h-full flex-col">
        <img
          className="w-full rounded-t-lg object-cover"
          src={featuredImageUrl.src || ''}
          alt={featuredImageUrl.alt || ''}
        />
        <div className="relative w-full flex-grow transform overflow-hidden rounded-lg bg-palette-black-4 p-8 text-left shadow-xl transition-all sm:w-full sm:max-w-6xl">
          <h2 className="mb-2 py-2 text-2xl font-bold">{title}</h2>
          <button
            role="button"
            className="btn mb-4 flex w-full min-w-[5rem] items-center justify-center rounded-full border-2 border-brand-primary bg-brand-primary px-3 py-1.5 text-center text-sm font-bold transition-colors hover:border-white hover:bg-white hover:text-brand-primary"
          >
            <span className="mr-2">
              {viewer.status === AuthStatus.User ? 'Go to course' : 'Enroll for Free'}
            </span>{' '}
            <IoArrowRedoOutline />
          </button>
          <p className="mb-4 text-sm text-gray-300">{description}</p>
        </div>
      </Link>
    </div>
  );
};
