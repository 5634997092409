import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Learn from 'screens/WebSite/Learn';
import { getMasterClass } from 'services/server/sanity/groq/masterClass';
import { MasterclassLadingPageProps } from 'screens/WebSite/Learn/types';

export const getStaticProps: GetStaticProps<MasterclassLadingPageProps> = async () => {
  const data = await getMasterClass();

  return {
    props: {
      data,
    },
    revalidate: 3600,
  };
};

export default function LearnComponent({ data }: InferGetStaticPropsType<typeof getStaticProps>) {
  return <Learn data={data} />;
}
