import React from 'react';
import { Parallax } from 'react-parallax';

import Head from 'components/Head';
import PageLayout from 'layouts/Website';
import { CardCourse } from './CardCourse';
import { MasterclassLadingPageProps } from './types';

export default function Learn({ data }: MasterclassLadingPageProps) {
  return (
    <>
      <Head
        title="Free Trading Courses | InsiderFinance Masterclasses"
        description="Enroll in InsiderFinance's free trading courses and learn the skills to master trading with expert-led courses for beginners and experienced traders alike."
        useCurrentUrl
      />
      <PageLayout hideTopBar isNavBordered>
        <div className="mx-auto max-w-full">
          <Parallax bgImage="/images/Masterclass.jpg" strength={-200}>
            <div className="py-48 text-center text-white">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-7xl">
                Free Trading Courses
              </h1>
              <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 lg:text-xl">
                Learn the skills to master trading with expert-led courses for beginners and
                experienced traders alike.
              </p>
            </div>
          </Parallax>
          <div className="container mx-auto py-16">
            <div className="mx-auto flex max-w-6xl flex-col items-center px-2 pb-16 text-white md:space-x-8 md:px-0 lg:columns-2 lg:flex-row">
              <p className="border-r border-r-gray-600 text-lg text-gray-300 md:pr-4">
                Ready to master trading concepts? Sharpen your trading skills with our comprehensive
                lessons covering a wide range of advanced trading topics.
              </p>

              <p className="m-0 p-0 text-lg text-gray-300 md:pl-4">
                New to trading? Build a solid foundation with our beginner-friendly lessons,
                designed to help you understand the basics and start trading with confidence.
              </p>
            </div>
            <div className="mx-auto mb-16 max-w-7xl">
              <div className="flex items-center justify-center text-center text-white">
                <div className="flex-grow border-t border-gray-600"></div>
                <h2 className="mx-4 text-3xl font-bold  lg:text-4xl">
                  Completely Free, No Card Required
                </h2>
                <div className="flex-grow border-t border-gray-600"></div>
              </div>
            </div>
            <div className="mx-auto max-w-7xl pb-16">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {data?.length > 0 &&
                  data?.map((course) => {
                    return <CardCourse key={course._id} {...course} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
}
